import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { format as formatPhone, toUri as toPhoneUri } from '../../utils/phone';
import { trackEvent } from '../../components/google-analytics';
import { Markdown } from '../../components/utils';
import { toUri as toEmailUri } from '../../utils/email';
import useBlockGeneral from '../../hooks/use-block-general';

import styles from './person.module.scss';

const Person = ({ name, role, phone, email, text, image, image_hover }) => {
	const [identifier] = ((typeof name === 'string' && name) || '')
		.toLowerCase()
		.split(' ');
	const { phone_prefix, email_prefix } = useBlockGeneral();

	const onClickPhone = () => {
		trackEvent('phone', `phone${identifier}`, undefined, 10);
	};

	const onClickEmail = () => {
		trackEvent('mail', `mail${identifier}`, undefined, 10);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.image}>
				<div className={styles.imgWrapper}>
					<Img className={styles.img} fluid={image.childImageSharp.fluid} />
				</div>
				<div className={styles.imgWrapperHover}>
					<Img className={styles.imgHover} fluid={image_hover.childImageSharp.fluid} />
				</div>
			</div>
			<div className={styles.content}>
				<h2 className={styles.title}>{name}</h2>
				<div className={styles.role}>{role}</div>
				<a href={toPhoneUri(phone)} onClick={onClickPhone} className={styles.phone}>
					<span className={styles.prefix}>{phone_prefix}</span>
					<span className={styles.value}>{formatPhone(phone)}</span>
				</a>
				<a href={toEmailUri(email)} onClick={onClickEmail} className={styles.phone}>
					<span className={styles.prefix}>{email_prefix}</span>
					<span className={styles.value}>{email}</span>
				</a>
				<div className={styles.text}>
					<Markdown content={text} />
				</div>
			</div>
		</div>
	);
};

Person.defaultProps = {
	name: null,
	role: null,
	phone: null,
	email: null,
	text: null,
	image: null,
	image_hover: null
};

Person.propTypes = {
	name: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	phone: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	image: PropTypes.shape({
		childImageSharp: PropTypes.shape({
			fluid: PropTypes.object.isRequired
		})
	}),
	image_hover: PropTypes.shape({
		childImageSharp: PropTypes.shape({
			fluid: PropTypes.object.isRequired
		})
	})
};

export default Person;
